body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

.coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('./assets/background.png') no-repeat center center fixed; /* Path to your background image */
  background-size: cover;
  color: white;
  text-align: center;
}

.logo-container {
  width: 150px;
  height: 150px;
}

.spinning-logo {
  width: 100%;
  height: 100%;
  animation: spin 5s linear infinite;
}

.message {
  font-size: 2.5rem;
  margin-top: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
